<!-- resources/js/MyComponent.vue -->
<template>
    <div>
        <div class="flex flex-wrap flex-col flex-1 gap-3" v-if="!this.submitted">
                <div class="h-auto min-h-fit rounded-lg p-3 rounded-lg flex-grow">
                    <div  @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile" :class="{ ' bg-pink-800': dragActive }" style="height: 280px" class=" flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
                        <div class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">

                            <label  for="file" class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" data-v-5d608623=""><g clip-path="url(#clip0_429_10970)" data-v-5d608623=""><circle cx="12" cy="11.999" r="9" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></circle><path d="M12 9V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path><path d="M9 12H15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path></g><defs data-v-5d608623=""><clipPath id="clip0_429_10970" data-v-5d608623=""><rect width="24" height="24" fill="white" data-v-5d608623=""></rect></clipPath></defs></svg>
                                <span class="">Upload your JPG Files</span>
                                <input  type="file" multiple name="fields" @change="handleFileSelection"  ref="file" accept=".pdf,.jpg,.jpeg,.png" id="file" class="hidden" />
                            </label>
                            <p class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black">Or drop files here.</p>
                        </div>
                    </div>
                </div>
 <!-- sample button-->
 <div>
            <button @click="addSampleFile"  v-if="this.files.length < 1"  type="button" class="flex justify-center  items-center  bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm text-md py-2 ml-3  space-x-2">
                <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" class="w-8 h-7 pl-2" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#0000000" stroke="#0000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>image-picture</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-360.000000, -99.000000)" fill="#ffffff"> <path d="M368,109 C366.896,109 366,108.104 366,107 C366,105.896 366.896,105 368,105 C369.104,105 370,105.896 370,107 C370,108.104 369.104,109 368,109 L368,109 Z M368,103 C365.791,103 364,104.791 364,107 C364,109.209 365.791,111 368,111 C370.209,111 372,109.209 372,107 C372,104.791 370.209,103 368,103 L368,103 Z M390,116.128 L384,110 L374.059,120.111 L370,116 L362,123.337 L362,103 C362,101.896 362.896,101 364,101 L388,101 C389.104,101 390,101.896 390,103 L390,116.128 L390,116.128 Z M390,127 C390,128.104 389.104,129 388,129 L382.832,129 L375.464,121.535 L384,112.999 L390,118.999 L390,127 L390,127 Z M364,129 C362.896,129 362,128.104 362,127 L362,126.061 L369.945,118.945 L380.001,129 L364,129 L364,129 Z M388,99 L364,99 C361.791,99 360,100.791 360,103 L360,127 C360,129.209 361.791,131 364,131 L388,131 C390.209,131 392,129.209 392,127 L392,103 C392,100.791 390.209,99 388,99 L388,99 Z" id="image-picture" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>
      <span class="pr-2">SAMPLE IMAGE</span>
    </button>
    <!-- Image model preview -->
    <div v-if="isResult" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
  <div class="bg-white p-4 mx-3 rounded-lg w-[720px]  relative animate-fade-in">
    <button @click="perviewImage" v-if="this.files.length > 0" class="absolute right-0 top-1 mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center  hover:bg-red-50">
      <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="15px" width="20px" viewBox="0 0 492 492" class="">
        <g>
          <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
        </g>
      </svg>
    </button>
    <!-- Image preview -->
    <img :src="isResult" class="rounded-lg m-8 pt-1 max-h-[500px] w-[720px] mx-auto object-contain" style="object-fit: cover;">
  </div>
</div>
  </div>
                <!-- show output -->
                <div class="flex md:w-full" v-if="this.files.length && !this.submitted">
                <div class="flex flex-wrap  mt-4 rounded-lg p-4 w-full">
                    <div class="flex justify-between w-full">
                        <h3 class="w-full">Images ({{this.files.length}})</h3>

                    </div>
                    <div class="w-full p-3 my-1 flex flex-wrap flex-row" v-for="(file, index) in files">
                        <div class="w-full flex flex-wrap flex-row justify-between gap-2  border-2 border-gray-200 rounded-md p-2  relative">
                            <div class="w-full flex flex-wrap flex-row justify-between">

                                <div class="flex flex-wrap gap-2">

                                    <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                                    <div class="flex flex-col my-auto">
                                         <span class="text-xs font-semibold text-red-600 my-2">{{ file.name.substr(file.name.length - 10) }}</span>
                                        <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                                    </div>
                                </div>
                                <div class="flex">
                                    <button @click="CropperPopupSet(index)" title="Cropper" class="inline-flex items-center top-1 right-1 my-auto">
                                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="22px" height="22px" viewBox="0 0 1920 1920">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"/>
                                            </svg>
                                        </div>
                                        </button>
                                        <button
                                    @click="settingPopup = true"
                                    title="Setting"
                                >
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22px"
                                            height="22px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </button>
                                    <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center top-1 right-1 my-auto">
                                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-end">
                <div class="mr-5">
                    <button  v-if="this.files.length>0 && !this.submitted && !conversionCompleted" @click="uploadFiles" :disabled="disableConvertButton" :class="{ 'bg-red-300': disableConvertButton, 'bg-red-800': !disableConvertButton, 'hover:bg-red-500 hover:shadow-sm': !disableConvertButton }" class="flex justify-center items-center py-2 px-3 me-2 mb-2 text-md font-medium text-white border rounded-md focus:outline-none bg-red-800 space-x-2 hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                         <img :src="'/images/circle-of-two-clockwise-arrows-rotation.png'" alt="convert" width="17px" height="10px" class="">
                       <span> Convert</span>
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                </div>
            </div>
                </div>
            </div>
                 <!-- Url add and convert jpg-to-word -->
            <div v-if="handleFileSelection">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700">
                <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
            </div>

            <div class="px-4" >
                <p class="text-base md:text-xl font-semibold text-black mb-2">Import from URL</p>
                <form>
                    <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div class="relative">
                        <input type="text" v-model="fileUrl" id="upload" class="block w-full pe-24  truncate p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Add file URL" />
                        <button type="submit" class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="uploadFiles">Upload</button>
                    </div>
                </form>
            </div>
        </div>
<!-- url end convert jpg-to-word -->
            </div>
        <div>
            <div class="flex flex-wrap shadow-2xl rounded-lg" v-if="this.files.length && this.submitted">
                <div class="w-full flex justify-between">
                    <h3>Results({{this.files.length}})</h3>
                    <button class="text-xs bg-transparent hover:bg-white-400 text-red-500 border-2 border-red-500 font-bold py-1 px-1 rounded inline-flex items-center transition-all duration-300" v-if="files.length > 1" @click="downloadAllDocs">Download All</button>
                </div>
                <div class="p-3 my-1 flex flex-wrap flex-col w-full" v-for="(file, index) in files" :key="index">
                    <div class="border-2 border-gray-200 rounded-md p-2 flex flex-wrap flex-row gap-2" >
                        <div class="w-auto text-center">
                            <img :src="file.dataURL" class="rounded-lg h-20 mx-auto object-contain" style="aspect-ratio: 16/9;object-fit: cover;">
                            <span class="text-xs">
                                 <span v-if="file.name.length<10">{{ file.name }}</span>
                                 <span v-else>{{ ".."+file.name.substr(file.name.length - 10)}}</span> - {{ file.reslut }}</span>
                        </div>
                        <div class=" relative flex-grow">
                            <div class="w-full flex flex-wrap" v-if="file.text">
                                <div class="md:w-1/2 mx-auto justify-center">
                                    Translate into:
                                    <div class="dropdown inline-block relative py-1 group">
                                        <button class="text-xs bg-transparent hover:bg-white-400 text-red-500 border-2 border-red-500 font-bold py-1 px-1 rounded inline-flex items-center transition-all duration-300" >
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-2 mx-1 w-2 md:h-2 md:w-2" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 5h7"></path><path d="M9 3v2c0 4.418 -2.239 8 -5 8"></path><path d="M5 9c0 2.144 2.952 3.908 6.7 4"></path><path d="M12 20l4 -9l4 9"></path><path d="M19.1 18h-6.2"></path></svg>
                                            <span>Select Language</span>
                                        </button>
                                        <ul class="dropdown-menu hidden group-hover:block group w-full absolute text-left text-gray-700 pt-1 w-full text-sm bg-white border border-gray-300 rounded shadow-lg" style="max-height: 200px; overflow-y: auto;">
                                            <li><a @click="translate(files.indexOf(file),'en', 'English')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">English</a></li>
                                            <li><a @click="translate(files.indexOf(file),'es', 'Spanish')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Spanish</a></li>
                                            <li><a @click="translate(files.indexOf(file),'ar', 'Arabic')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Arabic</a></li>
                                            <li><a @click="translate(files.indexOf(file),'hi', 'Hindi')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Hindi</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="md:w-1/2 flex flex-row justify-end my-1">
                                    <button v-if="file.text" @click="downloadDoc(file.text)" class="cursor-pointer mx-2 inline-flex gap-1 items-center py-[5px] px-[8px] text-xs rounded hover:bg-gray-400 bg-gray-300 justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 400 400" fill="#000000"><g stroke-width="0"/><g stroke-linecap="round" stroke-linejoin="round"/><g><g><path d="M325,105H250a5,5,0,0,1-5-5V25a5,5,0,1,1,10,0V95h70a5,5,0,0,1,0,10Z"/> <path d="M325,154.83a5,5,0,0,1-5-5V102.07L247.93,30H100A20,20,0,0,0,80,50v98.17a5,5,0,0,1-10,0V50a30,30,0,0,1,30-30H250a5,5,0,0,1,3.54,1.46l75,75A5,5,0,0,1,330,100v49.83A5,5,0,0,1,325,154.83Z"/> <path d="M300,380H100a30,30,0,0,1-30-30V275a5,5,0,0,1,10,0v75a20,20,0,0,0,20,20H300a20,20,0,0,0,20-20V275a5,5,0,0,1,10,0v75A30,30,0,0,1,300,380Z"/> <path d="M275,280H125a5,5,0,0,1,0-10H275a5,5,0,0,1,0,10Z"/> <path d="M200,330H125a5,5,0,0,1,0-10h75a5,5,0,1,1,0,10Z"/> <path d="M325,280H75a30,30,0,0,1-30-30V173.17a30,30,0,0,1,30.19-30l250,1.66a30.09,30.09,0,0,1,29.81,30V250A30,30,0,0,1,325,280ZM75,153.17a20,20,0,0,0-20,20V250a20,20,0,0,0,20,20H325a20,20,0,0,0,20-20V174.83a20.06,20.06,0,0,0-19.88-20l-250-1.66Z"/> <path d="M163.16,236H152.85V190.92H138.67v-8.24h38.67v8.24H163.16Z"/> <path d="M222.23,236H211l-11.8-21-12.5,21h-8.95l16.88-27.77-14.49-25.55h11.17l9.84,17.73,10.43-17.73h9L205.74,207Z"/> <path d="M247.15,236H236.84V190.92H222.66v-8.24h38.67v8.24H247.15Z"/></g></g></svg> Download .doc file
                                    </button>
                                    <button v-if="file.text" @click="copyText(file.text)" title="Copy Text" class="copy-text gap-2 text-xs inline-flex items-center p-[5px] rounded hover:bg-gray-400 bg-gray-300 justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4"><g transform="translate(-2.25 -2.25)"><path d="M5.173,16.787A2.926,2.926,0,0,1,2.25,13.864V5.173A2.926,2.926,0,0,1,5.173,2.25h8.691a2.926,2.926,0,0,1,2.923,2.923v.543a.75.75,0,1,1-1.5,0V5.173A1.424,1.424,0,0,0,13.864,3.75H5.173A1.424,1.424,0,0,0,3.75,5.173v8.691a1.424,1.424,0,0,0,1.423,1.423.75.75,0,0,1,0,1.5Z" transform="translate(0 0)"></path> <path data-v-5e38c1d8="" id="Rectangle_6894" data-name="Rectangle 6894" d="M2-.75h9.037A2.753,2.753,0,0,1,13.787,2v9.037a2.753,2.753,0,0,1-2.75,2.75H2a2.753,2.753,0,0,1-2.75-2.75V2A2.753,2.753,0,0,1,2-.75Zm9.037,13.037a1.251,1.251,0,0,0,1.25-1.25V2A1.251,1.251,0,0,0,11.037.75H2A1.251,1.251,0,0,0,.75,2v9.037A1.251,1.251,0,0,0,2,12.287Z" transform="translate(8.463 8.463)"></path></g></svg>
                                        Copy To Clipboard
                                    </button>
                                </div>
                            </div>
                            <textarea v-if="file.uploaded && file.text" rows="4" class="w-full border-2 border-gray-200 rounded-md min-h-fit" v-html="file.text"></textarea>
                            <div v-else-if="file.uploaded && (file.text === null || file.text === '')" class="w-full min-h-fit">
                                <div class="flex flex-wrap flex-row w-full justify-between items-center px-4">
                                    <div class="flex flex-wrap justify-between flex-row items-center gap-2">
                                        <img :src="'/images/limit-img.webp'" class="w-12 mx-auto">
                                        <div class="text-start">
                                            <p class="text-md w-full font-bold">No Text Found</p>
                                            <p class="text-sm w-full">You can try again if you think something is wrong.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap flex-col gap-4 justify-center">
                                        <button  @click="retryUpload(file)" class="cursor-pointer bg-[#DB2777] w-24 hover:bg-[#c5236b] flex text-center text-xs text-white px-2 py-1 shadow-lg rounded-lg gap-3">
                                            <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline">
                                                <path data-v-226c004c="" d="M16.9998 19.8333C17.7513 19.8333 18.4719 20.1318 19.0033 20.6632C19.5346 21.1946 19.8332 21.9152 19.8332 22.6667C19.8332 23.4181 19.5346 24.1388 19.0033 24.6701C18.4719 25.2015 17.7513 25.5 16.9998 25.5C16.2484 25.5 15.5277 25.2015 14.9964 24.6701C14.465 24.1388 14.1665 23.4181 14.1665 22.6667C14.1665 21.9152 14.465 21.1946 14.9964 20.6632C15.5277 20.1318 16.2484 19.8333 16.9998 19.8333ZM33.2348 12.5517L30.9823 22.3125L21.2498 20.06L26.6332 16.6883C24.6357 13.4583 21.0657 11.3333 16.9998 11.3333C11.404 11.3333 6.75732 15.385 5.83649 20.7258L3.04565 20.23C4.19315 13.5717 10.0015 8.5 16.9998 8.5C22.0715 8.5 26.534 11.1775 29.0415 15.1867L33.2348 12.5517Z" fill="white"></path>
                                            </svg>
                                            Re-Try
                                        </button>
                                    </div>
                                </div>
                                <div class="w-full text-center my-2">
                                    <div class="cf-turnstile max-w-xs mx-auto" data-sitekey="0x4AAAAAAAYPnENkBR7iR2js" data-callback="javascriptCallback"></div>
                                </div>
                            </div>
                            <div v-else class="animate-pulse w-full border-2 border-gray-200 rounded-md min-h-fit">
                                <div class="flex-1 space-y-1 w-4/6 pl-4 pr-8 py-1">
                                    <div class="p-1 bg-pink-800 rounded w-3/4"></div>
                                    <div class="space-y-1">
                                        <div class="p-1 bg-pink-800 rounded"></div>
                                        <div class="p-1 bg-pink-800 rounded w-5/6"></div>
                                        <div class="p-1 bg-pink-800 rounded"></div>
                                        <div class="p-1 bg-pink-800 rounded w-4/6"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full text-xs py-1" v-if="file.transltion">{{file.transltionLang}}</div>
                            <textarea v-if="file.transltion" rows="4" class="w-full border-2 border-gray-200 rounded-md min-h-fit" v-html="file.transltion"></textarea>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- settingpop component -->
        <SettingComponent :settingPopup="settingPopup" @update:settingPopup="updatePopup" :title="title" />
        <!-- cropper Pop up start.. -->
        <div v-if="cropperPopup" class="cropper-popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col">

            <div class="bg-white p-4 rounded-lg md:w-[720px] relative">
                <VuePictureCropper
                    :aspectRatio="1/1"
                    :boxStyle="{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#f8f8f8',
                        margin: 'auto'
                    }"
                    :img="pic"
                    :options="options"
                    @cropend="getResult"
                    ref="cropperRef"
                />
                <button class="inline-flex items-center my-auto absolute top-5 right-5 z-50 px-3 py-3 rounded-full hover:bg-red-50 bg-white" @click="cropperPopup = false">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                    <g>
                        <g>
                            <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                        </g>
                    </g>
                </svg>
                </button>

                <CropComponent  :isClodeModel="cropperPopup"
                @update:isClodeModel="cropperPopup = $event"  :confirmCrop="confirmCrop" :flipX="flipX" :flipY="flipY" :rotateTo="rotateTo" :reset="reset"/>
            </div>
        </div>
        <!-- cropper Pop up end.. -->
        <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import VuePictureCropper, { cropper }  from 'vue-picture-cropper';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
import 'cropperjs/dist/cropper.css';
import CropComponent from "./CropComponent.vue";
import SettingComponent from "./SettingComponent.vue";

export default {
    props: {
        translations: Object,
        files: Array
    },
    components: {
        VuePictureCropper,
        ErrorPopupComponent,
        CropComponent,
        SettingComponent
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: 'crop',
                aspectRatio: NaN,
            },
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",
            cropperPopup: false,
            pic: '',
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
            width: '',
            height: '',
            imageUrl:'/images/resize.webp',
            title:'JPG to WORD Converter',
            settingPopup:false
        };
    },
    methods: {
         updatePopup(value){
             this.settingPopup = value;
         },
        handleFileSelection(event) {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
                pdfURL: null,
                convertedPdfUrl: null,
            }));

            for (const file of newFiles) {
                this.uploadFile(file, this.quality); // Convert each file to PDF
            }

            this.files.push(...newFiles);
        },
        addSampleFile() {
    const img = new Image();
    img.src = this.imageUrl;
    img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
            if (blob) {
                this.files.push({
                    name: "sample.jpg",
                    type: "application/svg",
                    dataURL: URL.createObjectURL(blob),
                    uploaded: false,
                    file: new File([blob], "sample.jpg", { type: "application/svg" }),
                });
            }
        }, "image/jpeg");
    };
},
        rotateTo(e) {
            cropper.rotate(e)
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true?1:-1;
            cropper.scaleX(flipp)
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true?1:-1;
            cropper.scaleY(flipp)
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return
            cropper.reset()
        },

    },
    setup() {
        const serverErrorMessage = ref("");
        const yourErrorHeaderVariable = ref("");
        const language = ref('Select language');
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const cropperPopup = ref(false);
        const currentFileIndex = ref(null);
        const pic = ref('');

        const handleFileSelection = (event) => {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        const resetTurnstile = () => {

            if (window.turnstile && typeof window.turnstile.remove === 'function') {
                window.turnstile.remove();
            }

            window.turnstile.render('.cf-turnstile', {
                sitekey: '0x4AAAAAAAYPnENkBR7iR2js',
                callback: (newToken) => {
                    token.value = newToken;
                }
            });
        };

        const retryUpload = (file) => {
            if (!file) {

                alert('No file selected for upload');
                return;
            }
            resetTurnstile();

            uploadFile(file);
        };
        const uploadFiles = async () => {
            submitted.value = true;
            for (let i = 0; i < files.value.length; i++) {
                await uploadFile(files.value[i]);
            }
        };

        const remove = async (index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value = false;
            }
        };

        const downloadDoc = async (text, filename) => {
            var blob = new Blob([text], { type: 'application/msword' });
            var url = URL.createObjectURL(blob);
            var downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = `result-jpgconverters-com.doc`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };
        const downloadAllDocs = () => {
            files.value.forEach((file) => {
                if (file.text) {
                    downloadDoc(file.text);
                }
            });
        };
        const copyText = async (text) => {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            setTimeout(function () {
                copyText.classList.remove("active");
            }, 2500);
        };
        /*const downloadAllDocs = () => {
            files.value.forEach((file, index) => {
                if (file.transltion) {
                    const filename = `result-imagetotext-${index + 1}.doc`;
                    downloadDoc(file.transltion, filename);
                }
            });
        };*/

        const translate = async (index,lang,langtext) => {
            let file = files.value[index];
            file.transltionLang = langtext;
            files.value[index] = file;
            language.value = langtext;
            const formData = new FormData();
            formData.append("text", file.text);
            formData.append("to", lang); // Append the actual File object
            const config = {
                headers: { "Content-Type": "multipart/form-data" },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/translate-text", formData, config);
                file.transltion = response.data.text
                files.value[index] = file;
            } catch (error) {
                console.error("Error translate:", error);
            }
        };
        const uploadFile = async (file) => {
            const formData = new FormData();
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/convert-to-text", formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.text = response.data.text || null;

            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);

                if (error.response) {
                    const status = error.response.status;

                    if (status === 500) {
                        serverErrorMessage.value = "Internal Server Error";
                        yourErrorHeaderVariable.value = "Error 500";

                    } else if (status === 422) {
                        serverErrorMessage.value = error.response.data.message || "Unprocessable Entity";
                        yourErrorHeaderVariable.value = "Error 422";
                    }
                    else if (status === 400) {
                        serverErrorMessage.value ="Bad Request";
                        yourErrorHeaderVariable.value = "400";
                    }
                    else if (status === 408) {
                        serverErrorMessage.value ="Request Timeout";
                        yourErrorHeaderVariable.value = "Error 408";
                    }
                    else if (status === 503) {
                        serverErrorMessage.value ="Service Unavailable";
                        yourErrorHeaderVariable.value = "Error 503";
                    }
                }
            }
        };

        const removeAllFiles=()=>{
            files.value=[]
            submitted.value = false;
        }
        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName = files.value[currentFileIndex.value].name.replace(/\.[^/.]+$/, '') + '_cropped.png';

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File([croppedBlob], newFileName, { type: croppedBlob.type });
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };
        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };
        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };
        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value = false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            translate,
            dragActive,
            language,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            downloadDoc,
            cropperPopup,
            CropperPopupSet,
            confirmCrop,
            dataURLtoBlob,
            pic,
            downloadAllDocs,
            serverErrorMessage,
            yourErrorHeaderVariable,
            retryUpload,
            resetTurnstile,
            copyText,
            removeAllFiles
        };
    },
};

</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
