<template>
    <div class="space-y-6 my-7 scrollable-content">
      <h1 class="text-3xl font-bold text-center text-gray-800 mb-6">File Information</h1>
      <div v-for="(file, index) in files" :key="index" class="space-y-4">
        <div class="flex justify-between items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300">
          <span class="font-semibold text-gray-700">File Name:</span>
          <span class="text-gray-600">{{ file.name }}</span>
        </div>
        <div class="flex justify-between items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300">
          <span class="font-semibold text-gray-700">File Size:</span>
          <span class="text-gray-600">{{ Math.floor(file.file.size / 1024) + "KB" }}</span>
        </div>
        <div class="flex justify-between items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300">
          <span class="font-semibold text-gray-700">File Type:</span>
          <span class="text-gray-600">{{ file.type || "jpg" }}</span>
        </div>
        <div class="flex justify-between items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300">
          <span class="font-semibold text-gray-700">File Extension:</span>
          <span class="text-gray-600">{{ file.extension || this.isExtension }}</span>
        </div>
        <div v-if="isPreview" class="flex flex-col items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition duration-300">
          <span class="font-semibold text-gray-700 mb-2">File Preview:</span>
          <img :src="file.dataURL" class="rounded-lg h-32 object-contain shadow-sm" alt="File Image" />
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      files: Array, 
      isPreview: Boolean,
      isExtension: String,
    },
  };
  </script>
  