<template>
    <div v-if="settingPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-4 rounded-lg w-[720px] relative">
                <div class="flex my-4">
                    <!--cross button..-->
                    <button @click="closePopup" class="inline-flex items-center top-1 right-1 my-auto absolute top-4 right-4  my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                </g>
                            </g>
                        </svg>
                    </button>

                    <div class="my-auto mx-2 w-10 h-10 rounded-md border-2  flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="mx-2">
                        <p class="text-lg font-semibold">Options</p>
                        <span class="text-sm font-medium">{{ title }}</span>
                    </div>
                </div>
                <hr>
                <div class="my-5">
                    <div class="mb-1 flex justify-around">
                        <label class="my-auto block text-black dark:text-white">
                            Quality <span class="text-meta-1">*</span>
                        </label>
<!--                        <input v-model="quality" type="number" id="quality" name="quality" class="w-2/3 mr-2 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" min="0" max="1" step="1" />-->

                        <select v-model="quality" class="w-2/3">
                            <option value="1">High</option>
                            <option value="0.5">Medium</option>
                            <option value="0">Low</option>
                        </select>


                    </div>
                    <div class="w-[66%] text-left ml-[26%] mb-1">
                        <span class="text-[12px] leading-normal">
                            JPEg compression level from 1 (lowest image quality and highest compression) to 100 (best quality but least effective compression). The default is to estimate the quality based on your input image.
                        </span>
                    </div>
                </div>
                <hr>
                <div class="w-[90%] mx-auto">
                    <button @click="closePopup"  class="w-[46%] hover:bg-red-50  mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer">Cancel</button>
                    <button @click="closePopup"  class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    props: {
        settingPopup:Boolean,
        title:String,
    },
    data() {
        return {
            quality: this.modelValue || 1, 
        };
    },
    watch: {
        quality(newVal) {
            this.$emit('update:quality', newVal);
        },
    },
    methods: {
    closePopup() {
      this.$emit('update:settingPopup', false);
    }
  }
};
</script>