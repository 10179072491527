<template>
    <div v-if="isDeleteModel" class="fixed inset-0 shadow-lg flex justify-center items-center bg-gray-500 bg-opacity-50 animate-fade-in">
      <div class="w-11/12 sm:w-2/3 md:w-1/3 h-auto bg-white rounded-xl p-6">
        <div class="flex justify-center items-center w-full">
          <div class="flex justify-center items-center rounded-full p-5 bg-red-100">
            <img src="../../../public/images/deleteIcon.png" alt="Delete_Icon" class="object-contain" width="50px" height="50px"/>
          </div>
        </div>
        <div class="flex justify-center items-center leading-relaxed flex-col space-y-4">
          <h1 class="font-medium text-xl sm:text-2xl md:text-3xl text-black mt-2">Delete All?</h1>
          <p class="text-center text-sm sm:text-base md:text-lg w-full sm:w-10/12">
            Are you sure you want to delete all the files? This action will permanently erase all files, and once completed, it cannot be undone or recovered.
          </p>
        </div>
        <div class="flex flex-wrap justify-center mt-6 sm:mt-10 space-x-2 sm:space-x-4">
          <button 
            @click="closeModal"  
            class="w-5/12 sm:w-2/5 px-4 py-2 text-sm sm:text-base text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">
            Cancel
          </button>
          <button 
            @click="confirmDelete" 
            class="w-5/12 sm:w-2/5 px-4 py-2 bg-red-800 text-sm sm:text-base text-white rounded-lg cursor-pointer hover:bg-red-600">
            Yes, Delete
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isDeleteModel: Boolean,
      confirmDelete: Function
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      }
    }
  }
  </script>
  
 