<template>
    <div class="min-h-screen bg-gray-100 flex flex-col items-center p-6">
      <!-- File Upload Section -->
      <div class="w-full max-w-md bg-white rounded-lg shadow p-4">
        <h2 class="text-lg font-semibold text-gray-700 mb-4">PDF to JPG Converter</h2>
        <div
          @drop.prevent="dropFile"
          @dragover.prevent="dragover"
          @dragleave.prevent="dragleave"
          :class="{ 'bg-blue-100': dragActive }"
          class="border-2 border-dashed border-gray-300 rounded-lg p-6 flex flex-col items-center justify-center text-gray-500"
        >
          <lucide-file class="w-12 h-12 mb-2" />
          <p class="text-sm text-gray-600 mb-2">Drag & drop your PDF here or</p>
          <label class="cursor-pointer text-blue-500 hover:underline">
            Select a PDF
            <input type="file" @change="handleFileSelection" accept=".pdf" hidden />
          </label>
        </div>
      </div>
  
      <!-- Files List -->
      <div v-if="files.length && !submitted" class="w-full max-w-md mt-6 bg-white rounded-lg shadow p-4">
        <h3 class="text-lg font-semibold text-gray-700 mb-4">Uploaded Files</h3>
        <ul class="space-y-3">
          <li
            v-for="(file, index) in files"
            :key="index"
            class="flex justify-between items-center border-b pb-2"
          >
            <span>{{ file.name }}</span>
            <button
              @click="remove(index)"
              class="text-red-500 hover:underline"
            >
              Remove
            </button>
          </li>
        </ul>
        <button
          @click="uploadFiles"
          class="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
        >
          Convert to JPG
        </button>
      </div>
  
      <!-- Conversion Progress -->
      <div v-if="isConverting" class="mt-4 w-full max-w-md">
        <div class="bg-gray-200 rounded-full h-2">
          <div
            class="bg-blue-500 h-full rounded-full transition-all"
            :style="{ width: `${conversionProgress}%` }"
          ></div>
        </div>
        <p class="text-sm text-gray-600 mt-2">Converting page {{ currentPage }} of {{ totalPages }}</p>
      </div>
  
      <!-- Download Section -->
      <div v-if="submitted && files.length" class="w-full max-w-md mt-6 bg-white rounded-lg shadow p-4">
        <h3 class="text-lg font-semibold text-gray-700 mb-4">Converted Images</h3>
        <ul class="space-y-3">
          <li
            v-for="(file, index) in files"
            :key="index"
            class="flex justify-between items-center border-b pb-2"
          >
            <span>{{ file.name.replace(/\.[^/.]+$/, "") }}.zip</span>
            <button
              @click="downloadJpgs(file)"
              class="text-blue-500 hover:underline"
            >
              Download ZIP
            </button>
          </li>
        </ul>
      </div>
  
      <!-- Error Message -->
      <p v-if="errorMessage" class="mt-4 text-red-500">{{ errorMessage }}</p>
    </div>
  </template>
  

<script>
import { ref } from "vue";
import axios from "axios";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import JSZip from 'jszip';


GlobalWorkerOptions.workerSrc = '/node_modules/pdfjs-dist/build/pdf.worker.min.mjs';


export default{
    props: {
        translations:Object,
    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);

        const handleFileSelection = (event) => {
            const newFiles = Array.from(event.target.files).map((file) => {
                const extension = file.name.split('.').pop().toLowerCase();
                if (extension !== 'pdf') {
                    console.error("Unsupported file type:", extension);
                    return null; // Skip this file
                }

                return {
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
            }).filter((file) => file !== null);

            files.value.push(...newFiles);
        };

        const uploadFiles = async () => {
            submitted.value=true;
            for (let i = 0;i<files.value.length;i++){
                await uploadFile(files.value[i]);
            }
        };

        const download =async(index) => {
            alert('download')
        };
        const remove =async(index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value =false;
            }
        };

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            file.jpgBlobs = []; // Initialize jpgBlobs array

            const reader = new FileReader();

            reader.onload = async (event) => {
                const pdfData = new Uint8Array(event.target.result);
                const loadingTask = getDocument({ data: pdfData });

                try {
                    const pdf = await loadingTask.promise;
                    const totalPages = pdf.numPages;
                    const jpgBlobs = []; // Array to store JPG blobs for each page

                    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
                        const page = await pdf.getPage(pageNum);
                        const viewport = page.getViewport({ scale: 2.5 });
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;

                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport,
                        };

                        const renderTask = page.render(renderContext);
                        await renderTask.promise;

                        // Convert canvas to JPG blob
                        canvas.toBlob((blob) => {
                            jpgBlobs.push(blob); // Store the JPG blob for this page

                            if (jpgBlobs.length === totalPages) {
                                // All pages rendered and converted
                                // Now you can do something with jpgBlobs array, like uploading or displaying
                                console.log("All pages converted to JPG blobs:", jpgBlobs);
                                file.jpgBlobs = jpgBlobs; // Store the JPG blobs in the file object
                            }
                        }, 'image/jpeg');
                    }
                } catch (error) {
                    console.error("Error loading PDF for conversion:", error);
                    file.uploading = false;
                    file.error = "Error converting PDF";
                }
            };

            reader.readAsArrayBuffer(file.file);
        };

        const downloadJpgs = async (file) => {
            const zip = new JSZip();

            for (let i = 0; i < file.jpgBlobs.length; i++) {
                const jpgFileName = file.name.replace(/\.[^/.]+$/, "") + "_page" + (i + 1) + ".jpg";
                zip.file(jpgFileName, file.jpgBlobs[i]);
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = file.name.replace(/\.[^/.]+$/, "") + "_images.zip";
            downloadLink.click();
        };

        const getFileBaseName = (fileName) => {
            const nameParts = fileName.split('.');
            // Return the first part of the split array, which is the base name without extension
            return nameParts[0];
        };


        const uploadFileOld = async(file) => {
            const formData = new FormData();
            formData.append("convert", 'to-pdf');
            formData.append("images",file.file); // Append the actual File object

            const config={
                headers:{"Content-Type":"multipart/form-data"},
                onUploadProgress:(progressEvent) =>{
                    const progress = Math.round(
                        (progressEvent.loaded/progressEvent.total) * 100
                    );
                    file.uploading=true;
                    file.uploadProgress = progress;
                },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/file-upload-pdf",formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress=100;
                file.responseData=response.data;
                file.downloadPath = response.data.path;
            } catch (error){
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };
        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value=false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL:URL.createObjectURL(file),
                name:file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            downloadJpgs,
            getFileBaseName
        };
    },
};

</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
