<template>
  <div class="max-w-[900px] mx-auto p-8 font-sans">

    <div
      class="border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-all duration-300 mb-6 relative"
      :class="{
        'border-red-600 bg-red-50/20': isDragging,
        'border-green-500 bg-green-50/20': selectedFile,
        'hover:border-red-600': !selectedFile
      }"
      @dragover.prevent="isDragging = true"
      @dragleave.prevent="isDragging = false"
      @drop.prevent="handleFileDrop"
      @click="triggerFileInput"
    >
      <input
        type="file"
        ref="fileInput"
        accept=".docx"
        class="hidden"
        @change="handleFileSelect"
      />

      <div v-if="!selectedFile" class="flex flex-col items-center">
        <FileUp class="w-12 h-12 mb-4 text-red-600" />
        <p>Drag & drop your Word document here or click to browse</p>
        <p class="text-gray-600 text-sm mt-2">Supports .docx  files</p>
      </div>

      <div v-else class="flex items-center justify-center gap-2">
        <FileCheck class="w-12 h-12 text-green-500" />
        <p>{{ selectedFile.name }}</p>
        <button class="bg-transparent border-none text-red-500 cursor-pointer p-[5px] rounded-full flex items-center justify-center hover:bg-red-100/40" @click.stop="removeFile">
          <X class="w-[18px] h-[18px]" />
        </button>
      </div>
    </div>

    <button
      class="w-full p-4 bg-red-500 text-white border-none rounded font-bold cursor-pointer transition-colors duration-300 flex items-center justify-center gap-2 disabled:bg-red-300 disabled:cursor-not-allowed hover:bg-red-600"
      :disabled="!selectedFile || isConverting"
      @click="convertToJpgs"
    >
      <span v-if="!isConverting">Convert to JPGs</span>
      <span v-else class="flex items-center gap-2">
        <Loader2 class="w-[18px] h-[18px] animate-spin" />
        Convert to JPGs
      </span>
    </button>

    <!-- Unified loading experience with progress indicator -->
    <div v-if="isConverting" class="mt-4 p-3 bg-blue-50 text-blue-800 rounded">
      <div class="flex items-center gap-2 mb-2">
        <Loader2 class="w-[18px] h-[18px] animate-spin" />
        <span>{{ conversionStage }}: {{ currentPage }} of {{ totalPages }}</span>
      </div>
      <!-- Progress bar -->
      <div class="w-full bg-blue-100 rounded-full h-2.5">
        <div 
          class="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
          :style="{ width: `${(currentPage / Math.max(totalPages, 1)) * 100}%` }"
        ></div>
      </div>
    </div>

    <div v-if="errorMessage" class="mt-4 p-3 bg-red-50 text-red-800 rounded flex items-center gap-2">
      <AlertCircle class="w-[18px] h-[18px]" />
      {{ errorMessage }}
    </div>

    <div v-if="conversionComplete" class="mt-4 p-4 bg-green-50 rounded flex justify-between items-center">
      <div class="flex items-center gap-2 text-green-800">
        <CheckCircle class="w-[18px] h-[18px]" />
        <p>Conversion complete! ({{ jpgBlobs.length }} images)</p>
      </div>
      <button class="bg-green-500 text-white border-none rounded px-4 py-2 cursor-pointer flex items-center gap-2 font-bold hover:bg-green-600 transition-colors duration-300" @click="downloadJpgs">
        <Download class="w-[18px] h-[18px]" />
        Download JPGs
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { FileUp, FileCheck, X, Loader2, AlertCircle, CheckCircle, Download } from 'lucide-vue-next';
import mammoth from 'mammoth';
import { renderAsync } from 'docx-preview';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';

// Refs for component state
const fileInput = ref(null);
const selectedFile = ref(null);
const isDragging = ref(false);
const isConverting = ref(false);
const errorMessage = ref('');
const conversionComplete = ref(false);
const jpgBlobs = ref([]);  // Store array of JPG blobs
const previewContainer = ref(null);
const currentPage = ref(0); // Track current page
const totalPages = ref(0); // Track total pages
const conversionStage = ref('Processing');

// Create a hidden container for document preview
onMounted(() => {
  previewContainer.value = document.createElement('div');
  previewContainer.value.style.position = 'absolute';
  previewContainer.value.style.left = '-9999px';
  previewContainer.value.style.width = '794px'; // A4 width at 96 DPI
  document.body.appendChild(previewContainer.value);

  // Clean up on component unmount
  return () => {
    if (previewContainer.value && previewContainer.value.parentNode) {
      previewContainer.value.parentNode.removeChild(previewContainer.value);
    }
  };
});

// Trigger file input click
const triggerFileInput = () => {
  if (!isConverting.value) {
    fileInput.value.click();
  }
};

// Handle file selection from input
const handleFileSelect = (event) => {
  const file = event.target.files[0];
  if (file && (file.name.endsWith('.docx') || file.name.endsWith('.doc'))) {
    selectedFile.value = file;
    errorMessage.value = '';
    conversionComplete.value = false;
  } else if (file) {
    errorMessage.value = 'Please select a valid Word document (.docx or .doc)';
  }
};

// Handle file drop
const handleFileDrop = (event) => {
  isDragging.value = false;
  const file = event.dataTransfer.files[0];
  if (file && (file.name.endsWith('.docx') || file.name.endsWith('.doc'))) {
    selectedFile.value = file;
    errorMessage.value = '';
    conversionComplete.value = false;
  } else if (file) {
    errorMessage.value = 'Please select a valid Word document (.docx or .doc)';
  }
};

// Remove selected file
const removeFile = (event) => {
  event.stopPropagation();
  selectedFile.value = null;
  fileInput.value.value = '';
  conversionComplete.value = false;
  errorMessage.value = '';
};

// Word to JPG Conversion Orchestration
const convertToJpgs = async () => {
  if (!selectedFile.value) return;

  isConverting.value = true;
  errorMessage.value = '';
  conversionComplete.value = false;
  currentPage.value = 0; // Reset page counter
  totalPages.value = 0;
  jpgBlobs.value = []; // Clear previous results
  conversionStage.value = 'Rendering document';

  try {
    // Direct conversion from Word to JPGs
    await convertWordDocumentToJpgs();
  } catch (error) {
    console.error('Conversion failed:', error);
    errorMessage.value = 'Failed to convert document. Please try another file.';
    isConverting.value = false;
  }
};

// Direct conversion from Word to JPGs without PDF intermediate step
const convertWordDocumentToJpgs = async () => {
  try {
    // Clear the container
    previewContainer.value.innerHTML = '';

    // Render Word document to HTML
    if (selectedFile.value.name.endsWith('.docx')) {
      conversionStage.value = 'Rendering DOCX';
      await renderAsync(selectedFile.value, previewContainer.value, null, {
        inWrapper: false,
        ignoreWidth: false,
        ignoreHeight: false,
      });
    } else if (selectedFile.value.name.endsWith('.doc')) {
      conversionStage.value = 'Rendering DOC';
      const arrayBuffer = await selectedFile.value.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      previewContainer.value.innerHTML = result.value;
    } else {
      errorMessage.value = 'Unsupported file type.';
      isConverting.value = false;
      return;
    }

    // Get all elements in the container
    const elements = Array.from(previewContainer.value.children).filter(el => el.tagName !== 'STYLE');
    totalPages.value = elements.length;
    
    if (totalPages.value === 0) {
      // If no elements were found, try to convert the entire container as one page
      totalPages.value = 1;
      elements.push(previewContainer.value);
    }

    conversionStage.value = 'Converting to images';
    
    // Process elements in batches to prevent memory issues
    const batchSize = 3;
    for (let i = 0; i < elements.length; i += batchSize) {
      const end = Math.min(i + batchSize, elements.length);
      const pagePromises = [];

      for (let pageNum = i; pageNum < end; pageNum++) {
        currentPage.value = pageNum + 1; // Update current page (1-based)
        
        const pagePromise = async () => {
          try {
            const element = elements[pageNum];
            
            // Use higher scale for better quality
            const canvas = await html2canvas(element, {
              scale: 2.5,
              useCORS: true,
              logging: false,
              allowTaint: true,
              backgroundColor: '#ffffff'
            });
            
            // Convert canvas to JPG blob with high quality
            return new Promise(resolve => {
              canvas.toBlob(blob => {
                resolve(blob);
              }, 'image/jpeg', 1.0);
            });
          } catch (error) {
            console.error(`Error converting page ${pageNum + 1}:`, error);
            return null;
          }
        };
        
        pagePromises.push(pagePromise());
      }

      const blobs = await Promise.all(pagePromises);
      // Filter out any null blobs (failed conversions)
      jpgBlobs.value.push(...blobs.filter(blob => blob !== null));
    }

    if (jpgBlobs.value.length === 0) {
      errorMessage.value = 'Failed to convert document to images. Please try another file.';
      isConverting.value = false;
      return;
    }

    conversionComplete.value = true;
    isConverting.value = false;

  } catch (error) {
    console.error('Error during conversion:', error);
    errorMessage.value = 'An error occurred during the conversion process.';
    isConverting.value = false;
  }
};

// Download the generated JPGs as a zip file
const downloadJpgs = async () => {
  if (!jpgBlobs.value || jpgBlobs.value.length === 0) return;

  const zip = new JSZip();
  const folderName = selectedFile.value.name.replace(/\.[^/.]+$/, "") + "_images";
  const folder = zip.folder(folderName);

  for (let i = 0; i < jpgBlobs.value.length; i++) {
    const jpgFileName = `page_${(i + 1).toString().padStart(3, '0')}.jpg`;
    folder.file(jpgFileName, jpgBlobs.value[i]);
  }

  conversionStage.value = 'Creating ZIP file';
  isConverting.value = true;
  
  try {
    const zipBlob = await zip.generateAsync({ 
      type: 'blob',
      compression: "DEFLATE",
      compressionOptions: {
        level: 6
      }
    });
    
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = `${folderName}.zip`;
    downloadLink.click();
    
    // Clean up
    URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    console.error('Error creating ZIP file:', error);
    errorMessage.value = 'Failed to create ZIP file.';
  } finally {
    isConverting.value = false;
  }
};
</script>