<template>
    <div v-if="showModal" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 animate-fade-in ">
      <div class="relative bg-white rounded-lg shadow-lg w-full max-w-lg p-6 transform transition-all duration-300 scale-95">
        <div class="flex justify-between items-center border-b pb-3">
            <div class="flex space-x-3">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-8 h-8"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.24999 18L5.24999 20.25V15.75H2.25C1.85217 15.75 1.47064 15.5919 1.18934 15.3106C0.908034 15.0293 0.749999 14.6478 0.749999 14.25V2.25C0.749999 1.85217 0.908034 1.47064 1.18934 1.18934C1.47064 0.908034 1.85217 0.749999 2.25 0.749999H18.75C19.1478 0.749999 19.5293 0.908034 19.8106 1.18934C20.0919 1.47064 20.25 1.85217 20.25 2.25V6.71484" stroke="#FF9D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.24999 5.24999H15.75" stroke="#FF9D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.24999 9.74999H8.24999" stroke="#FF9D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M23.25 18.75H20.25V23.25L15.75 18.75H11.25V9.74999H23.25V18.75Z" stroke="#FF9D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M19.5 15H15" stroke="#FF9D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            <h3 class="text-2xl my-auto font-medium text-gray-900">Feedback</h3>
          </div>
          <button
            @click="closeModal"
            class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
          >
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
          </button>
        </div>
        <div class="mt-4">
          <p class="text-xl font-medium text-gray-900">Please share your valuable feedback!</p>
          <input 
            v-model="name"
            type="text"
            class="mt-2 w-full p-2 border rounded-md  outline-none"
            placeholder="Enter your name"
            required
          />
          <input 
            v-model="email"
            type="email"
            class="mt-4 w-full p-2 border rounded-md  focus:outline-none"
            placeholder="Enter your email"
            required
          />
  
          <div class="mt-3 flex justify-center text-4xl space-x-1">
            <label v-for="star in 5" :key="star" class="cursor-pointer">
              <input type="radio" v-model="rating" :value="star" class="hidden" />
              <span :class="rating >= star ? 'text-yellow-500' : 'text-gray-300'">★</span>
            </label>
          </div>
          <textarea
            v-model="feedback"
            class="mt-2 w-full p-2 border rounded-md "
            rows="5"
            placeholder="Express your feedback here..."
            required
          ></textarea>
          <p v-if="feedbackError" class="text-red-500 text-sm mt-2">⚠️ All fields are required.</p>
          <div v-if="isSuccess" class="mt-4 text-white py-2 flex justify-center items-center text-lg font-medium bg-green-500 rounded-md">
            ✅ Feedback submitted successfully!
          </div>
        </div>
        <div class="flex justify-center mt-4">
          <button @click="submitFeedback" class="py-1.5 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300">
            Submit
          </button>
        </div>
      </div>
    </div>
  </template>
<script>
import axios from 'axios';

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      email: "",
      rating: null,
      feedback: "",
      feedbackError: false,
      isSuccess: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async submitFeedback() {
      if (!this.name || !this.email || !this.rating || this.feedback.trim() === "") {
        this.feedbackError = true;
        return;
      }

      this.feedbackError = false;

      try {
       const response= await axios.post('/api/feedback', { 
          name: this.name, 
          email: this.email, 
          rating: this.rating, 
          feedback: this.feedback 
        });
       console.log(response);
       
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
          this.name = "";
          this.email = "";
          this.rating = null;
          this.feedback = "";
          this.closeModal();
        }, 2000);
      } catch (error) {
        console.error("Error submitting feedback:", error);
      }
    }
  },
};
</script>
  