<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!this.submitted">
            <div class="h-auto min-h-fit rounded-lg p-3 rounded-lg flex-grow">
                <div
                    @drop.prevent="dropFile"
                    @dragenter="dropFile"
                    @dragover="dropFile"
                    :class="{ ' bg-pink-800': dragActive }"
                    style="height: 280px"
                    class="flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2"
                >
                    <div
                        class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2"
                    >
                        <label
                            for="file"
                            class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25px"
                                height="25px"
                                viewBox="0 0 24 24"
                                fill="none"
                                data-v-5d608623=""
                            >
                                <g
                                    clip-path="url(#clip0_429_10970)"
                                    data-v-5d608623=""
                                >
                                    <circle
                                        cx="12"
                                        cy="11.999"
                                        r="9"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></circle>
                                    <path
                                        d="M12 9V15"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></path>
                                    <path
                                        d="M9 12H15"
                                        stroke="#FFFFFF"
                                        stroke-width="2.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        data-v-5d608623=""
                                    ></path>
                                </g>
                                <defs data-v-5d608623="">
                                    <clipPath
                                        id="clip0_429_10970"
                                        data-v-5d608623=""
                                    >
                                        <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                            data-v-5d608623=""
                                        ></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span class="">Upload your HEIC Files</span>
                            <input
                                type="file"
                                multiple
                                name="fields"
                                @change="handleFileSelection"
                                ref="file"
                                accept=".heic"
                                id="file"
                                class="hidden"
                            />
                        </label>
                        <p
                            class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black"
                        >
                            Or drop files here.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full" v-if="this.files.length && !this.submitted">
            <div class="flex flex-wrap mt-4 rounded-lg p-4 w-full">
                <div class="w-full flex justify-between">
                    <h3 class="my-auto ml-4">
                        Images({{ this.files.length }})
                    </h3>

                </div>
                <div
                    class="w-full p-3 my-1 flex flex-wrap flex-row"
                    v-for="(file, index) in files"
                >
                    <div
                        class="w-full flex flex-wrap flex-row justify-between gap-2 border-2 border-gray-200 rounded-md p-2 relative"
                    >
                        <div
                            class="w-full flex flex-wrap flex-row justify-between"
                        >
                            <div class="flex flex-wrap gap-2">
                                <img
                                    :src="file.dataURL"
                                    class="rounded-lg my-2 h-5 mx-auto object-contain"
                                    style="
                                        object-fit: cover;
                                        width: 60px;
                                        height: 55px;
                                    "
                                />
                                <div class="flex flex-col my-auto">
                                    <span
                                        class="text-xs font-semibold text-red-600 my-2"
                                    >{{
                                            file.name.substr(
                                                file.name.length - 10
                                            )
                                        }}</span
                                    >
                                    <span class="text-red-600">{{
                                            Math.floor(file.file.size / 1024) + "KB"
                                        }}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <p class="my-auto text-base font-semibold mx-2">
                                    Output
                                </p>
                                <select
                                    id="select"
                                    class="w-[77px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-red-50 cursor-pointer"
                                    disabled
                                >
                                    <option value="PDF">JPG</option>
                                </select>
                                <button
                                    @click="CropperPopupSet(index)"
                                    title="Cropper"
                                    class="inline-flex items-center top-1 right-1 my-auto"
                                >
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="#000000"
                                            width="22px"
                                            height="22px"
                                            viewBox="0 0 1920 1920"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"
                                            />
                                        </svg>
                                    </div>
                                </button>

                                <button
                                    @click="settingPopup = true"
                                    title="Setting"
                                >
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22px"
                                            height="22px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                        >
                                            <path
                                                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                                stroke="#292D32"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </button>
                                <button
                                    @click="remove(files.indexOf(file))"
                                    title="Remove file"
                                    class="inline-flex items-center top-1 right-1 my-auto"
                                >
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            fill="red"
                                            height="13px"
                                            width="13px"
                                            version="1.1"
                                            id="Layer_1"
                                            viewBox="0 0 492 492"
                                            xml:space="preserve"
                                        >
                                            <g>
                                                <g>
                                                    <path
                                                        d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                                    />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- sample button-->
        <div>
            <button @click="addSampleFile"  v-if="this.files.length < 1"  type="button" class="flex justify-center  items-center  bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm text-md py-2 ml-3  space-x-2">
                <svg viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" class="w-8 h-7 pl-2" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#0000000" stroke="#0000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>image-picture</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-360.000000, -99.000000)" fill="#ffffff"> <path d="M368,109 C366.896,109 366,108.104 366,107 C366,105.896 366.896,105 368,105 C369.104,105 370,105.896 370,107 C370,108.104 369.104,109 368,109 L368,109 Z M368,103 C365.791,103 364,104.791 364,107 C364,109.209 365.791,111 368,111 C370.209,111 372,109.209 372,107 C372,104.791 370.209,103 368,103 L368,103 Z M390,116.128 L384,110 L374.059,120.111 L370,116 L362,123.337 L362,103 C362,101.896 362.896,101 364,101 L388,101 C389.104,101 390,101.896 390,103 L390,116.128 L390,116.128 Z M390,127 C390,128.104 389.104,129 388,129 L382.832,129 L375.464,121.535 L384,112.999 L390,118.999 L390,127 L390,127 Z M364,129 C362.896,129 362,128.104 362,127 L362,126.061 L369.945,118.945 L380.001,129 L364,129 L364,129 Z M388,99 L364,99 C361.791,99 360,100.791 360,103 L360,127 C360,129.209 361.791,131 364,131 L388,131 C390.209,131 392,129.209 392,127 L392,103 C392,100.791 390.209,99 388,99 L388,99 Z" id="image-picture" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>
                <span class="pr-2">SAMPLE IMAGE</span>
            </button>
            <!-- Image model preview -->
            <div v-if="isResult" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div class="bg-white p-4 mx-3 rounded-lg w-[720px]  relative animate-fade-in">
                    <button @click="perviewImage" v-if="this.files.length > 0" class="absolute right-0 top-1 mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center  hover:bg-red-50">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="15px" width="20px" viewBox="0 0 492 492" class="">
                            <g>
                                <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                            </g>
                        </svg>
                    </button>
                    <!-- Image preview -->
                    <img :src="isResult" class="rounded-lg m-8 pt-1 max-h-[500px] w-[720px] mx-auto object-contain" style="object-fit: cover;">
                </div>
            </div>



        </div>
        <!-- end sample buttons -->
        <!-- convet button start -->
        <div class="flex justify-end">
            <div class="mr-5">
                <button  v-if="this.files.length>0 && !this.submitted && !conversionCompleted" @click="uploadFiles" :disabled="disableConvertButton" :class="{ 'bg-red-300': disableConvertButton, 'bg-red-800': !disableConvertButton, 'hover:bg-red-500 hover:shadow-sm': !disableConvertButton }" class="flex justify-center items-center py-2 px-3 me-2 mb-2 text-md font-medium text-white border rounded-md focus:outline-none bg-red-800 space-x-2 hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                    <img :src="'/images/circle-of-two-clockwise-arrows-rotation.png'" alt="convert" width="17px" height="10px" class="">
                    <span> Convert</span>
                    <i class="fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
        <!-- convert button end -->
        <div v-if="!this.submitted">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr
                    class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700"
                />
                <span
                    class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900"
                >or</span
                >
            </div>

            <div class="px-4">
                <p class="text-base md:text-xl font-semibold text-black mb-2">
                    Import from URL
                </p>
                <form>
                    <label
                        for="search"
                        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >Search</label
                    >
                    <div class="relative">
                        <input
                            type="text"
                            v-model="fileUrl"
                            id="upload"
                            class="block w-full p-4 ps-10 pe-24  truncate text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Add file URL"
                        />
                        <svg   v-if="!fileUrl"  fill="#000000" height="50px" width="20px" class="absolute top-0  left-5" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 54.971 54.971" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M51.173,3.801c-5.068-5.068-13.315-5.066-18.384,0l-9.192,9.192c-0.781,0.781-0.781,2.047,0,2.828 c0.781,0.781,2.047,0.781,2.828,0l9.192-9.192c1.691-1.69,3.951-2.622,6.363-2.622c2.413,0,4.673,0.932,6.364,2.623 s2.623,3.951,2.623,6.364c0,2.412-0.932,4.672-2.623,6.363L36.325,31.379c-3.51,3.508-9.219,3.508-12.729,0 c-0.781-0.781-2.047-0.781-2.828,0s-0.781,2.048,0,2.828c2.534,2.534,5.863,3.801,9.192,3.801s6.658-1.267,9.192-3.801 l12.021-12.021c2.447-2.446,3.795-5.711,3.795-9.192C54.968,9.512,53.62,6.248,51.173,3.801z"></path> <path d="M27.132,40.57l-7.778,7.778c-1.691,1.691-3.951,2.623-6.364,2.623c-2.412,0-4.673-0.932-6.364-2.623 c-3.509-3.509-3.509-9.219,0-12.728L17.94,24.306c1.691-1.69,3.951-2.622,6.364-2.622c2.412,0,4.672,0.932,6.363,2.622 c0.781,0.781,2.047,0.781,2.828,0s0.781-2.047,0-2.828c-5.067-5.067-13.314-5.068-18.384,0L3.797,32.793 c-2.446,2.446-3.794,5.711-3.794,9.192c0,3.48,1.348,6.745,3.795,9.191c2.446,2.447,5.711,3.795,9.191,3.795 c3.481,0,6.746-1.348,9.192-3.795l7.778-7.778c0.781-0.781,0.781-2.047,0-2.828S27.913,39.789,27.132,40.57z"></path> </g> </g></svg>
                        <button type="submit" @click="handleUpload" v-if="fileUrl" class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Fetch</button>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <div
                class="flex flex-wrap shadow-lg mt-4 rounded-lg p-4"
                v-if="this.files.length && this.submitted"
            >
                <div class="w-full flex justify-between">
                    <h3 class="my-auto ml-5">
                        Results({{ this.files.length }})
                    </h3>
                </div>
                <div
                    class="flex flex-wrap justify-between w-full p-3 my-1"
                    v-for="(file, index) in files"
                    :key="index"
                >
                    <div
                        class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2"
                    >
                        <div class="flex flex-wrap gap-2">
                            <img
                                :src="file.dataURL"
                                class="rounded-lg h-5 my-1 mx-auto object-contain"
                                style="
                                    object-fit: cover;
                                    width: 60px;
                                    height: 55px;
                                "
                            />
                            <div class="flex flex-col my-auto">
                                <span
                                    class="text-xs font-semibold text-red-600 my-1"
                                >{{
                                        file.name.substr(file.name.length - 10)
                                    }}</span
                                >
                                <span class="text-red-600">{{
                                        Math.floor(file.file.size / 1024) + "KB"
                                    }}</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div  class="flex flex-wrap" >
                                <!-- File Info -->
                                <div class="my-auto cursor-pointer"  @click="toggleFileInfo(index)" >
                                    <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2">
                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 14.5V17.5M12 11.5H12.01M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                        <a
                                            class="mr-3.5">FILE INFO </a>
                                    </div>
                                </div>
                                <!-- Preview Button -->
                                <div class="my-auto cursor-pointer mx-2" @click="perviewImage(file)"  v-if="this.files.length >0">
                                    <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2">
                                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" xml:space="preserve" stroke="#ffffff" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M51.8,25.1C47.1,15.6,37.3,9,26,9S4.9,15.6,0.2,25.1c-0.3,0.6-0.3,1.3,0,1.8C4.9,36.4,14.7,43,26,43 s21.1-6.6,25.8-16.1C52.1,26.3,52.1,25.7,51.8,25.1z M26,37c-6.1,0-11-4.9-11-11s4.9-11,11-11s11,4.9,11,11S32.1,37,26,37z"></path> <path d="M26,19c-3.9,0-7,3.1-7,7s3.1,7,7,7s7-3.1,7-7S29.9,19,26,19z"></path> </g> </g></svg>
                                        <a
                                            class="mr-3.5">PREVIEW</a>
                                    </div>
                                </div>
                                <!-- Download button -->
                                <div class="my-auto">
                                    <div class="flex justify-between items-center w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm py-2 cursor-pointer">
                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#ffffff"></path> </g></svg>
                                        <a  target="_blank" :href="file.dataURL" :download="file.name" class="mr-3.5">DOWNLOAD</a>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50"
                            >
                                <button
                                    @click="remove(files.indexOf(file))"
                                    title="Remove file"
                                    class="inline-flex items-center top-2 right-1"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        fill="red"
                                        height="13px"
                                        width="13px"
                                        version="1.1"
                                        id="Layer_1"
                                        viewBox="0 0 492 492"
                                        xml:space="preserve"
                                    >
                                        <g>
                                            <g>
                                                <path
                                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- download all button -->
                <div class="w-full flex justify-end space-x-2  mr-3 mt-2" v-if="!isLoading">
                    <!-- Feed Back Button -->
                    <div>
                        <button @click="showFeedbackModal = true" class=" flex text-md justify-center  items-center bg-red-800 p-2 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm text-md py-2 px-4 space-x-1 ">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" class="w-8 h-5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.24999 18L5.24999 20.25V15.75H2.25C1.85217 15.75 1.47064 15.5919 1.18934 15.3106C0.908034 15.0293 0.749999 14.6478 0.749999 14.25V2.25C0.749999 1.85217 0.908034 1.47064 1.18934 1.18934C1.47064 0.908034 1.85217 0.749999 2.25 0.749999H18.75C19.1478 0.749999 19.5293 0.908034 19.8106 1.18934C20.0919 1.47064 20.25 1.85217 20.25 2.25V6.71484" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.24999 5.24999H15.75" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.24999 9.74999H8.24999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M23.25 18.75H20.25V23.25L15.75 18.75H11.25V9.74999H23.25V18.75Z" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M19.5 15H15" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            FEEDBACK
                        </button>
                    </div>
                    <button v-if="conversionCompleted && files.length > 1" @click="downloadAllAsZip" class="flex text-md justify-center  items-center bg-red-800 p-2 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm text-md py-2 px-2 space-x-1">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-10 h-6"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#ffffff"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#ffffff"></path> </g></svg>
                        DOWNLOAD All
                    </button>
                    <div>
                        <button @click="isDeleteModel=true" type="button" class=" flex text-md justify-center w-32 items-center bg-red-800 p-2 mt-1 text-white rounded hover:bg-red-500 hover:shadow-sm text-md py-2 px-4 space-x-1">
                            <img src="../../../public/images/deleteAll.png" alt="Delete All" width="22px" height="15px" class="mr-2">
                            DELETE
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- option pop up start.. -->
        <SettingComponent :settingPopup="settingPopup" @update:settingPopup="updatePopup" :title="title" />
        <!-- option pop up end.. -->

        <!-- cropper Pop up start.. -->
        <div
            v-if="cropperPopup"
            class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col"
        >
            <div class="w-[720px] relative">
                <button
                    class="inline-flex items-center my-auto absolute top-5 right-5 z-50 px-2 py-2 rounded bg-white"
                    @click="cropperPopup = false"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        fill="red"
                        height="13px"
                        width="13px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 492 492"
                        xml:space="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"
                                />
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="bg-white p-4 rounded-lg w-[720px] relative">
                    <VuePictureCropper
                        :aspectRatio="1 / 1"
                        :boxStyle="{
                            width: '100%',
                            height: '500px',
                            backgroundColor: '#f8f8f8',
                            margin: 'auto',
                        }"
                        :img="pic"
                        :options="options"
                        @cropend="getResult"
                        ref="cropperRef"
                    />

                    <CropComponent  :isClodeModel="cropperPopup"
                                    @update:isClodeModel="cropperPopup = $event"  :confirmCrop="confirmCrop" :flipX="flipX" :flipY="flipY" :rotateTo="rotateTo" :reset="reset"/>
                </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->
        <!-- component render -->
        <FeedBackFormComponent :showModal="showFeedbackModal" @closeModal="showFeedbackModal = false" />
        <div v-if="showFileInfo !== null" class="fixed inset-0 bg-black bg-opacity-50 px-1 flex justify-center items-center z-50 scrollable-content">
            <div class="bg-white rounded-2xl shadow-lg w-full md:w-1/2 p-6 overflow-auto max-h-[100vh] relative animate-fade-in">
                <button
                    class="absolute top-2 right-4 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full hover:bg-red-50 flex items-center justify-center transition duration-300"
                    @click="closeModal"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="13px" width="13px" viewBox="0 0 492 492">
                        <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
            c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
            c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
            L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
            c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
            c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
            c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                    </svg>
                </button>
                <ShowFileInfoComponent :files="files" :isPreview="true" isExtension="heic" v-if="showFileInfo !== null"/>
            </div>
        </div>
        <DeleteModel
            :isDeleteModel="isDeleteModel"
            :confirmDelete="confirmDelete"
            @closeModal="isDeleteModel = false"
        />
        <!-- Image model preview -->
        <div v-if="isResult" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div class="bg-white p-4 mx-3 rounded-lg w-[720px]  relative animate-fade-in">
                <button @click="perviewImage" v-if="this.files.length > 0" class="absolute right-0 top-1 mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center  hover:bg-red-50">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="red" height="15px" width="20px" viewBox="0 0 492 492" class="">
                        <g>
                            <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                        </g>
                    </svg>
                </button>
                <!-- Image preview -->
                <img :src="isResult" class="rounded-lg m-8 pt-1 max-h-[500px] w-[720px] mx-auto object-contain" style="object-fit: cover;">
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import axios from "axios";
import JSZip from "jszip";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import "cropperjs/dist/cropper.css";
import heic2any from "heic2any";
import CropComponent from "./CropComponent.vue";
import FeedBackFormComponent from "./FeedBackFormComponent.vue";
import ShowFileInfoComponent from "./showFileInfoComponent.vue";
import DeleteModel from "./DeleteModel.vue";
import SettingComponent from "./SettingComponent.vue";
export default {
    props: {
        translations: Object,
        files: Array,
    },
    components: {
        VuePictureCropper,
        CropComponent,
        FeedBackFormComponent,
        ShowFileInfoComponent,
        DeleteModel,
        SettingComponent
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: "crop",
                aspectRatio: NaN,
            },
            cropperPopup: false,
            showFeedbackModal:false,
            showFileInfo:null,
            pic: "",
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
            imageUrl:'/images/heic-jpg.jpeg',
            isResult:null,
            title:'HEIC to JPG Converter'
        };
    },
    methods: {
        addSampleFile(){
            const img = new Image();
            img.src = this.imageUrl;
            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                canvas.getContext('2d').drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                    if (blob) {
                        this.files.push({
                            name: "image.jpeg",
                            type: "application/heic",
                            dataURL: URL.createObjectURL(blob),
                            uploaded: false,
                            file: new File([blob], "image.jpeg", { type: "application/heic" }),
                        });
                    }
                }, "image/jpeg");
            };
        },
        toggleFileInfo(index) {
            this.showFileInfo = this.showFileInfo === index ? null : index;
        },
        closeModal(){
            this.showFileInfo=null
        },
        perviewImage(file) {
            this.isResult = file.dataURL;
        },
        rotateTo(e) {
            cropper.rotate(e);
            // Reset last selection and results
        },
        updatePopup(value) {
      this.settingPopup = value;
    },

        flipX() {
            let flipp = this.flipXValue === true ? 1 : -1;
            cropper.scaleX(flipp);
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true ? 1 : -1;
            cropper.scaleY(flipp);
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return;
            cropper.reset();
        },
    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const settingPopup = ref(false);
        const cropperPopup = ref(false);
        const conversionCompleted = ref(false);
        const disableConvertButton = ref(false);
        const quality = ref(1);
        const fileUrl = ref("");
        const currentFileIndex = ref(null);
        const pic = ref("");
        const showFileInfo = ref(null);
        const isDeleteModel=ref(false);
        const handleFileSelection = async (event) => {
            const newFiles = await Promise.all(
                Array.from(event.target.files).map(async (file) => {
                    const objectURL = URL.createObjectURL(file);

                    try {
                        const blob = await heic2any({
                            blob: file,
                            toType: "image/jpeg",
                            quality: parseFloat(quality.value),
                        });

                        const img = new Image();
                        img.src = URL.createObjectURL(blob);
                        await new Promise((resolve) => {
                            img.onload = resolve;
                        });

                        const width = img.width;
                        const height = img.height;

                        if (isNaN(width) || isNaN(height)) {
                            throw new Error("Invalid image dimensions");
                        }

                        const canvas = document.createElement("canvas");
                        canvas.width = width;
                        canvas.height = height;
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);

                        return new Promise((resolve) => {
                            canvas.toBlob((jpegBlob) => {
                                const newFileName =
                                    file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                                const jpgFile = new File(
                                    [jpegBlob],
                                    newFileName,
                                    {
                                        type: "image/jpeg",
                                        lastModified: new Date(),
                                    }
                                );

                                setTimeout(() => {
                                    resolve({
                                        dataURL: URL.createObjectURL(jpgFile),
                                        name: newFileName,
                                        file: jpgFile,
                                        uploading: false,
                                        uploaded: true,
                                        uploadProgress: 100,
                                        error: null,
                                    });

                                    URL.revokeObjectURL(objectURL);
                                }, 500);
                            }, "image/jpeg");
                        });
                    } catch (error) {
                        console.error("Error converting HEIC image:", error);
                        return {
                            dataURL: objectURL,
                            name: file.name,
                            file: file,
                            uploading: false,
                            uploaded: false,
                            uploadProgress: 0,
                            error: "Error converting HEIC image",
                        };
                    }
                })
            );

            files.value = newFiles;
            disableConvertButton.value = false;
        };
        const confirmDelete=()=>{
            removeAllFiles();
            isDeleteModel.value=false;
        };

        const removeAllFiles = () => {
            files.value = []; // Empty the files array
            submitted.value = false;
            showFileInfo.value = null;
            conversionCompleted.value = false; // Reset conversion completed flag
        };

        const uploadFiles = async () => {
            submitted.value = true;
            for (let i = 0; i < files.value.length; i++) {
                if (files.value[i].uploaded) {
                    continue;
                }
                await uploadFile(files.value[i]);
            }
            conversionCompleted.value = true;
        };

        const download = async (index) => {
            alert("download");
        };

        const downloadAllAsZip = async () => {
            const zip = new JSZip();

            for (const file of files.value) {
                const response = await fetch(file.dataURL);
                const blob = await response.blob();
                zip.file(file.name, blob);
            }

            const zipBlob = await zip.generateAsync({ type: "blob" });

            // Create a URL for the Blob
            const url = URL.createObjectURL(zipBlob);

            // Create a temporary anchor element
            const a = document.createElement("a");
            a.href = url;
            a.download = "converted_files.zip";

            // Append the anchor to the body (not visible)
            document.body.appendChild(a);

            // Programmatically click the anchor to trigger the download
            a.click();

            // Remove the anchor from the document
            document.body.removeChild(a);

            // Revoke the Blob URL to release memory
            URL.revokeObjectURL(url);
        };

        const showDownloadAllButton = computed(() => {
            const jpgFiles = files.value.filter(
                (file) =>
                    file.name.toLowerCase().endsWith(".jpg") ||
                    file.name.toLowerCase().endsWith(".jpeg")
            );
            return jpgFiles.length > 1;
        });

        const remove = async (index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value = false;
            }
        };

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            try {
                const blob = await heic2any({
                    blob: file.file,
                    toType: "image/jpeg",
                    quality: parseFloat(quality.value),
                });

                const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                const jpgFile = new File([blob], newFileName, {
                    type: "image/jpeg",
                    lastModified: new Date(),
                });
                const objectURL = URL.createObjectURL(jpgFile);

                setTimeout(() => {
                    file.uploadProgress = 100;
                    file.uploading = false;
                    file.uploaded = true;

                    file.dataURL = URL.createObjectURL(jpgFile);
                    file.name = newFileName;
                    URL.revokeObjectURL(objectURL);
                }, 500);
            } catch (error) {
                file.uploading = false;
                file.error = "Error converting HEIC image";
            }
        };

        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName =
                files.value[currentFileIndex.value].name.replace(
                    /\.[^/.]+$/,
                    ""
                ) + "_cropped.jpg";

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File(
                [croppedBlob],
                newFileName,
                { type: croppedBlob.type }
            );
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };

        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(",")[1]);
            const mimeString = dataURL
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };

        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };

        const uploadFileOld = async (file) => {
            const formData = new FormData();
            formData.append("convert", "to-jpg");
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                const response = await axios.post(
                    "/file-upload-convert",
                    formData,
                    config
                );
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.downloadPath = response.data.path;
            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };

        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const handleUpload = async (event) => {
            event.preventDefault();
            const isImage = await checkImageURL(fileUrl.value);
            if (!isImage) {
                alert("Invalid format: Only image URLs are allowed.");
                return;
            }

            try {
                const response = await fetch(fileUrl.value);
                if (!response.ok) {
                    console.error(
                        "Error:",
                        response.status,
                        response.statusText
                    );
                    alert("Error fetching image from the URL.");
                    return;
                }

                const blob = await response.blob();
                const file = new File(
                    [blob],
                    fileUrl.value.substring(fileUrl.value.lastIndexOf("/") + 1),
                    { type: blob.type }
                );

                const imageUrl = URL.createObjectURL(blob);
                const fileObject = {
                    dataURL: imageUrl,
                    name: file.name,
                    type: blob.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
                files.value.push(fileObject);
                disableConvertButton.value = false;
                fileUrl.value = "";
            } catch (error) {
                console.error("Error handling image upload:", error);
            }
        };

        const checkImageURL = async (url) => {
            console.log("Checking image URL:", url);
            const response = await fetch(url);
            console.log("Fetch response:", response);
            if (!response.ok) {
                console.error("Error:", response.status, response.statusText);
                throw new Error("Failed to fetch image.");
            }

            const contentType = response.headers.get("content-type");
            console.log("Content-Type:", contentType);
            if (
                contentType &&
                (contentType.startsWith("image/") ||
                    contentType.startsWith("img/"))
            ) {
                const imageData = await response.blob();
                console.log("Image Data:", imageData);
                if (imageData && imageData.type.startsWith("image/")) {
                    console.log("Image URL is valid.");
                    return true;
                } else {
                    throw new Error("Response data is not an image.");
                }
            } else {
                throw new Error("Invalid content type for image.");
            }
        };

        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value = false;
            const newFiles = Array.from(event.dataTransfer.files).map(
                (file) => ({
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file, // Store the actual File object
                })
            );

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            quality,
            settingPopup,
            cropperPopup,
            download,
            downloadAllAsZip,
            conversionCompleted,
            showDownloadAllButton,
            removeAllFiles,
            disableConvertButton,
            fileUrl,
            handleUpload,
            CropperPopupSet,
            confirmCrop,
            pic,
            showFileInfo,
            confirmDelete,
            isDeleteModel
        };
    },
};
</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
