import './bootstrap';
import '../css/app.css';

import { createApp } from 'vue'; // Import Vue 3's createApp function
import ImageToTextComponent from './Components/ImageToTextComponent.vue';
import JpgToSvgComponent from './Components/JpgToSvgComponent.vue';
import SvgToJpgComponent from './Components/SvgToJpgComponent.vue';
import JpgToPdfComponent from './Components/JpgToPdfComponent.vue';
import WebpToJpgComponent from './Components/WebpToJpgComponent.vue';
import JfifToJpgComponent from './Components/JfifToJpgComponent.vue';
import TiffToJpgComponent from './Components/TiffToJpgComponent.vue';
import JpegToJpgComponent from './Components/JpegToJpgComponent.vue';
import PngToJpgComponent from './Components/PngToJpgComponent.vue';
import HeicToJpgComponent from './Components/HeicToJpgComponent.vue';
import jpgToPngComponent from './Components/JpgToPngComponent.vue';
/*import PrintScreenComponent from './Components/PrintScreenComponent.vue';*/
import MergeJpgComponent from './Components/MergeJpgComponent.vue';
import AvifToJpgComponent from './Components/AvifToJpgComponent.vue';
import PsdToJpgComponent from './Components/PsdToJpgComponent.vue';
import WebpToPngComponent from './Components/WebpToPngComponent.vue';
import homePageComponent from './Components/homePageComponent.vue';
import JpgToWordComponent from './Components/JpgToWordComponent.vue';
import ErrorPopupComponent from './Components/ErrorPopupComponent.vue';
import JpgToJpegComponent from './Components/JpgToJpegComponent.vue';
import PdfToJpgComponent from './Components/PdfToJpgComponent.vue';
import WordToJpgComponent from './Components/WordToJpgComponent.vue';

import HtmlToJpgComponent from './Components/HtmlToJpgComponent.vue';
/*import JpgCompreserComponent from './Components/JpgCompreserComponent.vue';*/
/*import JpgCompreserComponent from './Components/JpgCompreserComponent.vue';
import gifTojpgComponent from './Components/gifTojpgComponent.vue'
import GifToJpgComponent from './Components/GifToJpgComponent.vue'
import PdfToWordComponent from './Components/PdfToWordComponent.vue'

import JpgToWebpComponent from './Components/JpgToWebpComponent.vue'
import DropdownComponent from './Components/dropdown.vue';
import ExcelToJpgComponent from './Components/ExcelToJpgComponent.vue'
import ConvertToJpegComponent from './Components/ConvertToJpegComponent.vue'
import AccordionComponent from './Components/AccordionComponent.vue'
import VueTurnstile from './Components/VueTurnstile.vue'*/
import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf";

GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.mjs",
    import.meta.url
).toString();

const app = createApp({
    components: {
        ImageToTextComponent,
        JpgToSvgComponent,
        SvgToJpgComponent,
        JpgToPdfComponent,
        WebpToJpgComponent,
        JfifToJpgComponent,
        TiffToJpgComponent,
        JpegToJpgComponent,
        PngToJpgComponent,
        HeicToJpgComponent,
        jpgToPngComponent,
        /*PrintScreenComponent,*/
        MergeJpgComponent,
        AvifToJpgComponent,
        PsdToJpgComponent,
        HtmlToJpgComponent,
        WebpToPngComponent,
        homePageComponent,
        JpgToWordComponent,
        ErrorPopupComponent,
        JpgToJpegComponent,
        PdfToJpgComponent,
        WordToJpgComponent




/*      JpgCompreserComponent,*/
/*      JpgToWebpComponent,
        ExcelToJpgComponent,
        ConvertToJpegComponent,

        DropdownComponent,
        gifTojpgComponent,
        GifToJpgComponent,
        PdfToWordComponent,

        AccordionComponent,
        VueTurnstile*/


    },

    mounted() {
        // ScrollMove button click buttom to Top
        document.addEventListener('DOMContentLoaded', () => {
            const buttons = document.querySelectorAll('.scrollMove');
            buttons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    e.preventDefault();
                    document.body.scrollIntoView({
                        top: 0,
                        behavior: 'smooth',
                    });
                });
            });
        });
    },
});
app.mount('#app');


document.addEventListener("DOMContentLoaded", () => {
    const scrollButton = document.getElementById("scrollMove");
    if (scrollButton) { // Ensure scrollButton exists before adding event listener
        scrollButton.addEventListener("click", (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    document.getElementById('toggleMenu').addEventListener('click', function () {
        let menu = document.getElementById('menuItems');
        menu.classList.toggle('hidden');
    });
});
